module.exports = {
  siteTitle: 'Portfolio Daniel Grießer', // <title>
  manifestName: 'Daniel Grießer',
  manifestShortName: 'Portfolio', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#009cff',
  manifestThemeColor: '#009cff',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Daniel Grießer',
  heading1: 'Fullstack Developer',
  heading2: 'Agile Master',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/DaGrisa',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:daniel@grisa.at',
    },
  ],
};
